export const folders = [
  {
    title: "CS 170: Algorithms",
    notes: [
      { "title": "Divide and conquer: fibonacci and multiplication", "link": "http://jamestfang.com/notes/170/divide_and_conquer_0.pdf" }, 
      { "title": "Divide and conquer: general algorithm design", "link": "http://jamestfang.com/notes/170/divide_and_conquer_1.pdf" }, 
      { "title": "Fast fourier transform (FFT)", "link": "http://jamestfang.com/notes/170/fft.pdf" }, 
      { "title": "Graphs - DFS, DAGs, SCCs", "link": "http://jamestfang.com/notes/170/graph_DFS_DAG_SCC.pdf" }, 
      { "title": "Graphs - shortest paths", "link": "http://jamestfang.com/notes/170/graph_shortest_paths.pdf" }, 
      { "title": "Graphs - minimum spanning tree (MST)", "link": "http://jamestfang.com/notes/170/graph_mst.pdf" }, 
      { "title": "Greedy algorithms", "link": "http://jamestfang.com/notes/170/greedy_algorithms.pdf" }, 
      { "title": "Dynamic programming", "link": "http://jamestfang.com/notes/170/dp.pdf" }, 
      { "title": "Dynamic programming continued", "link": "http://jamestfang.com/notes/170/dp_cont.pdf" }, 
      { "title": "Linear programming", "link": "http://jamestfang.com/notes/170/lp.pdf" }, 
      { "title": "Duality and reductions", "link": "http://jamestfang.com/notes/170/duality_reductions.pdf" }, 
      { "title": "NP completeness", "link": "http://jamestfang.com/notes/170/np_completeness.pdf" }, 
      { "title": "NP completeness continued", "link": "http://jamestfang.com/notes/170/np_completeness_cont.pdf" }, 
      { "title": "Approximation algrithms", "link": "http://jamestfang.com/notes/170/approximation_alg.pdf" }, 
      { "title": "Primality and randomness", "link": "http://jamestfang.com/notes/170/primality_randomness.pdf" }
    ],
  }, // CS170
  {
    title: "CS 189: Machine Learning",
    notes: [
      { "title": "Introduction to Classification", "link": "http://jamestfang.com/notes/189/intro_classifiers.pdf" }, 
      { "title": "Classifiers", "link": "http://jamestfang.com/notes/189/classifiers.pdf" }, 
      { "title": "Perceptron algorithm (continued)", "link": "http://jamestfang.com/notes/189/perceptron.pdf" }, 
      { "title": "Soft margin SVMs", "link": "http://jamestfang.com/notes/189/svms.pdf" }, 
      { "title": "ML abstractions and algorithms", "link": "http://jamestfang.com/notes/189/algorithms.pdf" }, 
      { "title": "Decision theory (risk minimization)", "link": "http://jamestfang.com/notes/189/decision_theory.pdf" }, 
      { "title": "Gaussian discriminant analysis, MLE", "link": "http://jamestfang.com/notes/189/gda.pdf" }, 
      { "title": "Anisotropic Gaussians + eigen-v's", "link": "http://jamestfang.com/notes/189/anisotropic_gaussians.pdf" }, 
      { "title": "Anisotropic Gaussians continued", "link": "http://jamestfang.com/notes/189/anisotropic_gaussians2.pdf" }, 
      { "title": "Regression", "link": "http://jamestfang.com/notes/189/regression.pdf" }, 
      { "title": "Newton's method", "link": "http://jamestfang.com/notes/189/newtons_method.pdf" }, 
      { "title": "Statistical justifications for regression, bias-variance decomposition", "link": "http://jamestfang.com/notes/189/justification_bv.pdf" }, 
      { "title": "Ridge regression (Tikhonov Regularization)", "link": "http://jamestfang.com/notes/189/ridge_regression.pdf" }, 
      { "title": "Midterm review", "link": "http://jamestfang.com/notes/189/midterm.pdf" }, 
      { "title": "Midterm review continued", "link": "http://jamestfang.com/notes/189/midterm2.pdf" }, 
      { "title": "Decision trees", "link": "http://jamestfang.com/notes/189/decision_trees.pdf" }, 
      { "title": "Decision trees continued", "link": "http://jamestfang.com/notes/189/dts_cont.pdf" }, 
      { "title": "Kernels", "link": "http://jamestfang.com/notes/189/kernels.pdf" }, 
      { "title": "Neural networks", "link": "http://jamestfang.com/notes/189/nns.pdf" }, 
      { "title": "Neurons", "link": "http://jamestfang.com/notes/189/neurons.pdf" }, 
      { "title": "Heuristics for NNs, CNNs", "link": "http://jamestfang.com/notes/189/heuristics_nns.pdf" }, 
      { "title": "Unsupervised learning and PCA", "link": "http://jamestfang.com/notes/189/unsupervised_pca.pdf" }, 
      { "title": "Clustering", "link": "http://jamestfang.com/notes/189/clustering.pdf" }, 
      { "title": "Spectral graph clustering", "link": "http://jamestfang.com/notes/189/spectral_graph_clustering.pdf" }, 
      { "title": "Clustering continued, random projection, latent factor analysis, high-dimensional geometry", "link": "http://jamestfang.com/notes/189/clustering_cont.pdf" }, 
      { "title": "Adaboost", "link": "http://jamestfang.com/notes/189/adaboost.pdf" }, 
      { "title": "Nearest neighbors algorithms", "link": "http://jamestfang.com/notes/189/nearest_n_algorithms.pdf" }, 
      { "title": "Final review", "link": "http://jamestfang.com/notes/189/final.pdf" }, 
      { "title": "Final review continued", "link": "http://jamestfang.com/notes/189/final2.pdf" }, 
      { "title": "Pandas", "link": "http://jamestfang.com/notes/189/pandas.pdf" }
    ],
  }, // CS189
  {
    title: "CS 162: Operating Systems",
    notes: [
      { "title": "Fundamental concepts of operating systems", "link": "http://jamestfang.com/notes/162/1intro_fundamental_os_concepts.pdf" }, 
      { "title": "Processes: OS representation & syscalls", "link": "http://jamestfang.com/notes/162/2processes_syscalls.pdf" }, 
      { "title": "Threads & concurrency", "link": "http://jamestfang.com/notes/162/3threads.pdf" }, 
      { "title": "Intro to file systems", "link": "http://jamestfang.com/notes/162/4filesystems.pdf" }, 
      { "title": "Scheduling", "link": "http://jamestfang.com/notes/162/5scheduling.pdf" }, 
      { "title": "Synchronization operations", "link": "http://jamestfang.com/notes/162/6synchronization.pdf" }, 
      { "title": "Sockets: intro to communication", "link": "http://jamestfang.com/notes/162/7sockets.pdf" }, 
      { "title": "Interprocess communication & remote procedure calls", "link": "http://jamestfang.com/notes/162/8rpcs.pdf" }, 
      { "title": "Fair scheduling & system performance", "link": "http://jamestfang.com/notes/162/9performance_fair_scheduling.pdf" }, 
      { "title": "Deadlock, scheduling & synchronization", "link": "http://jamestfang.com/notes/162/10deadlock.pdf" }, 
      { "title": "Address translation", "link": "http://jamestfang.com/notes/162/11address_translation.pdf" }, 
      { "title": "Virtual memory", "link": "http://jamestfang.com/notes/162/12virtual_memory.pdf" }, 
      { "title": "Demand paging", "link": "http://jamestfang.com/notes/162/13demand_paging.pdf" }, 
      { "title": "Virtual machines", "link": "http://jamestfang.com/notes/162/14virtual_machines.pdf" }, 
      { "title": "IO subsystems", "link": "http://jamestfang.com/notes/162/15io.pdf" }, 
      { "title": "Data storage to file systems", "link": "http://jamestfang.com/notes/162/16fs_data_storage.pdf" }, 
      { "title": "Filesystems", "link": "http://jamestfang.com/notes/162/17filesystems.pdf" }, 
      { "title": "Buffering & reliability in filesystems", "link": "http://jamestfang.com/notes/162/18buffering_reliability_fs.pdf" }, 
      { "title": "Distributed filesystems", "link": "http://jamestfang.com/notes/162/19distributed_fs.pdf" }, 
      { "title": "Distributed KV store, 2PC", "link": "http://jamestfang.com/notes/162/20distributed_kv_2pc.pdf" }, 
      { "title": "Cloud applications", "link": "http://jamestfang.com/notes/162/21cloud_apps.pdf" }, 
      { "title": "TCP & e2e architecture", "link": "http://jamestfang.com/notes/162/22tcp.pdf" }, 
      { "title": "Security: authentication & authorization", "link": "http://jamestfang.com/notes/162/23security.pdf" }, 
      { "title": "OS perspective on Android", "link": "http://jamestfang.com/notes/162/24android.pdf" }
    ]
  },
  {
    title: "EECS 120: Signals & Systems",
    notes: [
      { "title": "Complex exponential (review)", "link": "http://jamestfang.com/notes/120/complex_e.pdf" }, 
      { "title": "Discrete time exponential, impulse/unit step, LTI and frequency response", "link": "http://jamestfang.com/notes/120/dt_lti_fr.pdf" }, 
      { "title": "Convolution and frequency response, causality of LTI systems", "link": "http://jamestfang.com/notes/120/convolution_fr_causality.pdf" }, 
      { "title": "Interconnections of LTI system, BIBO stability, linear constant coefficient differential equations (LCCDEs)", "link": "http://jamestfang.com/notes/120/bibo_stability_lccde.pdf" }, 
      { "title": "Systems described by LCCDEs, DT wrap up", "link": "http://jamestfang.com/notes/120/lccde_dt.pdf" }, 
      { "title": "Continuous time impulse/unit step, convolution, frequency response of CT-LTI systems", "link": "http://jamestfang.com/notes/120/ct_convolution_lti_fr.pdf" }, 
      { "title": "Continuous time systems described by LCCDEs, freqeuncy responses, block diagrams, feedback systems", "link": "http://jamestfang.com/notes/120/ct_lccde_fdb.pdf" }, 
      { "title": "Fourier analysis: discrete time Fourier series (DTFS)", "link": "http://jamestfang.com/notes/120/dtfs.pdf" }, 
      { "title": "DTFS and Dicrete Fourier Transform", "link": "http://jamestfang.com/notes/120/dtfs_dft.pdf" }, 
      { "title": "Contiuous time Fourier series (CTFS)", "link": "http://jamestfang.com/notes/120/ctfs.pdf" }, 
      { "title": "Discrete time Fourier Transform (DTFT)", "link": "http://jamestfang.com/notes/120/dtft.pdf" }, 
      { "title": "Continuous time Fourier transform (CTFT)", "link": "http://jamestfang.com/notes/120/ctft.pdf" }, 
      { "title": "Amplitude modulation", "link": "http://jamestfang.com/notes/120/amp_mod.pdf" }, 
      { "title": "Sampling", "link": "http://jamestfang.com/notes/120/sampling.pdf" }, 
      { "title": "Sampling cont.", "link": "http://jamestfang.com/notes/120/sampling_cont.pdf" }, 
      { "title": "Z transform", "link": "http://jamestfang.com/notes/120/z_transform.pdf" }, 
      { "title": "Steady state and transient + zero input and zero output analyses", "link": "http://jamestfang.com/notes/120/ss_zizo.pdf" }, 
      { "title": "Z transform cont.", "link": "http://jamestfang.com/notes/120/z_transform_cont.pdf" }, 
      { "title": "Laplace transform", "link": "http://jamestfang.com/notes/120/laplace.pdf" }, 
      { "title": "Steady state-transient decomposition for CT-LTI systems", "link": "http://jamestfang.com/notes/120/ss_decomp_ctlti.pdf" }, 
      { "title": "ZSR/ZIR for Laplace, bode plots", "link": "http://jamestfang.com/notes/120/zsr_bode.pdf" }, 
      { "title": "Feedback control", "link": "http://jamestfang.com/notes/120/fdb_ctrl.pdf" }
    ],
  }, // EECS120
  {
    title: "CS 186: Databases",
    notes: [
      { "title": "SQL", "link": "http://jamestfang.com/notes/186/sql.pdf" }, 
      { "title": "DBMS", "link": "http://jamestfang.com/notes/186/dbms.pdf" }, 
      { "title": "B+ Trees", "link": "http://jamestfang.com/notes/186/b_trees.pdf" }, 
      { "title": "Buffer management", "link": "http://jamestfang.com/notes/186/bfr_management.pdf" }, 
      { "title": "Relational algebra", "link": "http://jamestfang.com/notes/186/rel_alg.pdf" }, 
      { "title": "Sorting and hashing", "link": "http://jamestfang.com/notes/186/sort_hash.pdf" }, 
      { "title": "Iterators and joins", "link": "http://jamestfang.com/notes/186/iterators_joins.pdf" }, 
      { "title": "Parallel query processing", "link": "http://jamestfang.com/notes/186/parallel_processing.pdf" }, 
      { "title": "DB design: ER models", "link": "http://jamestfang.com/notes/186/er_models.pdf" }, 
      { "title": "DB design: Functional dependencies & normalization", "link": "http://jamestfang.com/notes/186/fds_normalization.pdf" }, 
      { "title": "Query optimization: plan space", "link": "http://jamestfang.com/notes/186/plan_space.pdf" }, 
      { "title": "Query optimization: cost and search", "link": "http://jamestfang.com/notes/186/cost_search.pdf" }, 
      { "title": "Text search", "link": "http://jamestfang.com/notes/186/text_search.pdf" }, 
      { "title": "Transactions and concurrency control", "link": "http://jamestfang.com/notes/186/xacts_concurrency.pdf" }, 
      { "title": "Recovery", "link": "http://jamestfang.com/notes/186/recovery.pdf" }, 
      { "title": "Distributed transactions", "link": "http://jamestfang.com/notes/186/distributed_xacts.pdf" }
    ],
  }, // CS186
  {
    title: "EECS 127: Optimization",
    notes: [
      { "title": "Vectors", "link": "http://jamestfang.com/notes/127/vectors.pdf" }, 
      { "title": "Matrices", "link": "http://jamestfang.com/notes/127/matrices.pdf" }, 
      { "title": "Matrices continued", "link": "http://jamestfang.com/notes/127/matrices_cont.pdf" }, 
      { "title": "Symmetric matrices and EVD", "link": "http://jamestfang.com/notes/127/symmetric_matrices_evd.pdf" }, 
      { "title": "SVD", "link": "http://jamestfang.com/notes/127/svd.pdf" }, 
      { "title": "Linear equations", "link": "http://jamestfang.com/notes/127/linear_eqs.pdf" }, 
      { "title": "Least squares", "link": "http://jamestfang.com/notes/127/least_squares.pdf" }, 
      { "title": "LPs", "link": "http://jamestfang.com/notes/127/lps.pdf" }, 
      { "title": "QPs", "link": "http://jamestfang.com/notes/127/qps.pdf" }, 
      { "title": "QPs with extra slides", "link": "http://jamestfang.com/notes/127/qps_slide5_6_8_12_13_29.pdf" }, 
      { "title": "SOCPs", "link": "http://jamestfang.com/notes/127/socps.pdf" }, 
      { "title": "Robust optimization", "link": "http://jamestfang.com/notes/127/robust_opt.pdf" }, 
      { "title": "Convexity", "link": "http://jamestfang.com/notes/127/convexity.pdf" }, 
      { "title": "Convex problems", "link": "http://jamestfang.com/notes/127/cvx_probs.pdf" }, 
      { "title": "Weak Duality", "link": "http://jamestfang.com/notes/127/weak_duality.pdf" }, 
      { "title": "Strong Duality", "link": "http://jamestfang.com/notes/127/strong_duality.pdf" }, 
      { "title": "Strong Duality extra slide", "link": "http://jamestfang.com/notes/127/strong_duality_slide8.pdf" }, 
      { "title": "Optimality conditions", "link": "http://jamestfang.com/notes/127/optimality_conds.pdf" }, 
      { "title": "Machine Learning", "link": "http://jamestfang.com/notes/127/machine_learning.pdf" }, 
      { "title": "Control", "link": "http://jamestfang.com/notes/127/control.pdf" }, 
      { "title": "Energy", "link": "http://jamestfang.com/notes/127/energy.pdf" }, 
      { "title": "Algorithms", "link": "http://jamestfang.com/notes/127/algorithms.pdf" }, 
      { "title": "Final cheatsheet", "link": "http://jamestfang.com/notes/127/final_cheatsheet.pdf" }
      /*,{ "title": "Textbook: El Ghaoui", "link" : "127/el_ghaoui_txtbook.pdf" }*/
    ],
  }, // EECS127
  {
    title: "Math 104: Real analysis",
    notes: [
      { "title": "Induction and Completeness Axiom", "link": "http://jamestfang.com/notes/104/induction_completeness_axiom.pdf" }, 
      { "title": "Archimedean property, sequences", "link": "http://jamestfang.com/notes/104/archimedean_prop_sequences.pdf" }, 
      { "title": "Sequences", "link": "http://jamestfang.com/notes/104/sequences.pdf" }, 
      { "title": "Limit theorems and bounds", "link": "http://jamestfang.com/notes/104/limit_thms_bounds.pdf" }, 
      { "title": "Monotone sequences", "link": "http://jamestfang.com/notes/104/monotone_sequences.pdf" }, 
      { "title": "Cauchy sequences", "link": "http://jamestfang.com/notes/104/cauchy_sequences.pdf" }, 
      { "title": "More on subsequences", "link": "http://jamestfang.com/notes/104/subsequences_cont.pdf" }, 
      { "title": "Limsup and liminf", "link": "http://jamestfang.com/notes/104/limsup_liminf.pdf" }, 
      { "title": "Series and convergence", "link": "http://jamestfang.com/notes/104/series.pdf" }, 
      { "title": "MT1 Review", "link": "http://jamestfang.com/notes/104/104_mt1_review.pdf" }, 
      { "title": "Continuous functions", "link": "http://jamestfang.com/notes/104/continuous_functions.pdf" }, 
      { "title": "Continuous functions continued", "link": "http://jamestfang.com/notes/104/continuous_functions_cont.pdf" }, 
      { "title": "Intermediate Value Theorem", "link": "http://jamestfang.com/notes/104/intermediate_value_thm.pdf" }, 
      { "title": "Uniform continuity", "link": "http://jamestfang.com/notes/104/uniform_continuity_cont.pdf" }, 
      { "title": "Limits of functions", "link": "http://jamestfang.com/notes/104/function_limits_cont.pdf" }, 
      { "title": "Differentiation", "link": "http://jamestfang.com/notes/104/differentiation.pdf" }, 
      { "title": "Mean Value Theorem", "link": "http://jamestfang.com/notes/104/mean_value_thm.pdf" }, 
      { "title": "Integrals", "link": "http://jamestfang.com/notes/104/integrals.pdf" }, 
      { "title": "Riemann Integrals", "link": "http://jamestfang.com/notes/104/riemann_integrals.pdf" }, 
      { "title": "MT2 Review", "link": "http://jamestfang.com/notes/104/104_mt2_review.pdf" }, 
      { "title": "Riemann Integrals continued", "link": "http://jamestfang.com/notes/104/riemann_integrals_cont.pdf" }, 
      { "title": "Integration properties", "link": "http://jamestfang.com/notes/104/integration_properties.pdf" }, 
      { "title": "Sequences and series of functions", "link": "http://jamestfang.com/notes/104/sequences_and_series_of_fxns.pdf" }, 
      { "title": "Sequences of functions continued", "link": "http://jamestfang.com/notes/104/sequences_of_fxns_cont.pdf" }, 
      { "title": "Metric spaces", "link": "http://jamestfang.com/notes/104/metric_spaces.pdf" }, 
      { "title": "Closed and open sets", "link": "http://jamestfang.com/notes/104/closed_open_sets.pdf" }, 
      { "title": "Final review", "link": "http://jamestfang.com/notes/104/104_final_review.pdf" },
      /*{ "title": "Textbook: Ross", "link" : "104/ross_txtbook.pdf" }*/
    ],
  }, // CS170
  {
    title: "CS 188: Artificial Intelligence",
    notes: [
      { "title": "Search", "link": "http://jamestfang.com/notes/188/search.pdf" }, 
      { "title": "Constraint Satisfaction Problems (CSPs)", "link": "http://jamestfang.com/notes/188/csps.pdf" }, 
      { "title": "Search with other agents", "link": "http://jamestfang.com/notes/188/search_w_other_agents.pdf" }, 
      { "title": "Markov Decision Problems (MDPs)", "link": "http://jamestfang.com/notes/188/mdps.pdf" }, 
      { "title": "Reinforcement Learning (RL)", "link": "http://jamestfang.com/notes/188/rl.pdf" }, 
      { "title": "Probabilistic Reasoning", "link": "http://jamestfang.com/notes/188/prob_reasoning.pdf" }, 
      { "title": "Bayes' Nets", "link": "http://jamestfang.com/notes/188/bn.pdf" }, 
      { "title": "Sampling from Bayes' Nets", "link": "http://jamestfang.com/notes/188/bn_sampling.pdf" }, 
      { "title": "Decision networks and the value of perfect information (VPI)", "link": "http://jamestfang.com/notes/188/decision_networks_vpi.pdf" }, 
      { "title": "Hidden Markov Models (HMMs) and particle filtering", "link": "http://jamestfang.com/notes/188/hmms_particle_filtering.pdf" }, 
      { "title": "Naive Bayes", "link": "http://jamestfang.com/notes/188/naive_bayes.pdf" }, 
      { "title": "Perceptrons", "link": "http://jamestfang.com/notes/188/perceptrons.pdf" }, 
      { "title": "Deep Learning", "link": "http://jamestfang.com/notes/188/deep_learning.pdf" }, 
      { "title": "Note 1: Search", "link": "http://jamestfang.com/notes/188/n1.pdf" }, 
      { "title": "Note 2: CSPs", "link": "http://jamestfang.com/notes/188/n2.pdf" }, 
      { "title": "Note 3: Games", "link": "http://jamestfang.com/notes/188/n3.pdf" }, 
      { "title": "Note 4: MDPs", "link": "http://jamestfang.com/notes/188/n4.pdf" }, 
      { "title": "Note 5: RL", "link": "http://jamestfang.com/notes/188/n5.pdf" }, 
      { "title": "Note 6: Bayes' Nets", "link": "http://jamestfang.com/notes/188/n6.pdf" }, 
      { "title": "Note 7: Decision networks", "link": "http://jamestfang.com/notes/188/n7.pdf" }, 
      { "title": "Note 8: HMMs", "link": "http://jamestfang.com/notes/188/n8.pdf" }, 
      { "title": "Note 9: Naive Bayes (ML)", "link": "http://jamestfang.com/notes/188/n9.pdf" }, 
      { "title": "Note 10: Perceptrons", "link": "http://jamestfang.com/notes/188/n10.pdf" }
    ],
  }, // CS188
  {
    title: "EECS 126: Probability",
    notes: [
      { "title": "Probability overview 1", "link": "http://jamestfang.com/notes/126/prob_overview1.pdf" }, 
      { "title": "Probability overview 2", "link": "http://jamestfang.com/notes/126/prob_overview2.pdf" }, 
      { "title": "Discrete random variables", "link": "http://jamestfang.com/notes/126/discrete_rv1.pdf" }, 
      { "title": "Discrete RVs continued and Entropy", "link": "http://jamestfang.com/notes/126/discrete_rv2_entropy.pdf" }, 
      { "title": "Continuous random variables", "link": "http://jamestfang.com/notes/126/cont_rvs.pdf" }, 
      { "title": "Mins/maxes, Normal distribution, Convolution", "link": "http://jamestfang.com/notes/126/mins_maxes_normal_convol.pdf" }, 
      { "title": "Convolution, Moment Generating Functions (MGFs)", "link": "http://jamestfang.com/notes/126/convol_mgfs.pdf"
      }, 
      { "title": "Bounds: Markov, Chebyshev, Chernoff", "link": "http://jamestfang.com/notes/126/bounds.pdf" }, 
      { "title": "Limits: SLLN, WLLN, convergence, CLT", "link": "http://jamestfang.com/notes/126/limits.pdf" }, 
      { "title": "Information theory, Binary erasure channel (BEC)", "link": "http://jamestfang.com/notes/126/info_theory_bec.pdf"
      }, 
      { "title": "Finite Markov Chains", "link": "http://jamestfang.com/notes/126/finite_mcs.pdf" }, 
      { "title": "General Markov Chains", "link": "http://jamestfang.com/notes/126/general_mcs.pdf" }, 
      { "title": "Poisson Process", "link": "http://jamestfang.com/notes/126/poisson_process.pdf" }, 
      { "title": "Continuous time Markov Chains", "link": "http://jamestfang.com/notes/126/ctmcs.pdf" }, 
      { "title": "Random Graphs", "link": "http://jamestfang.com/notes/126/random_graphs.pdf" }, 
      { "title": "MLE and MAP", "link": "http://jamestfang.com/notes/126/mle_map.pdf" }, 
      { "title": "Hypothesis Testing", "link": "http://jamestfang.com/notes/126/hypothesis_testing.pdf" }, 
      { "title": "Estimation: LLSE, MMSE", "link": "http://jamestfang.com/notes/126/estimation.pdf" }, 
      { "title": "Joint Gaussian RVs and Kalman Filter", "link": "http://jamestfang.com/notes/126/jg_kalman.pdf" }, 
      { "title": "HMMs and Viterbi Algorithm", "link": "http://jamestfang.com/notes/126/hmms_viterbi.pdf" }, 
      { "title": "Expectation maximization (EM)", "link": "http://jamestfang.com/notes/126/em.pdf" },
      /*{ "title": "Textbook: Bertsekas & Tsitsiklis", "link" : "126/txtbook.pdf" }*/
    ],
  }, // EECS126
  {
    title: "Physics 7B: Heat + E&M",
    notes: [
      { "title": "Thermodynamics and Ideal Gas Law", "link": "http://jamestfang.com/notes/7b/thermodynamics_igl.pdf" }, 
      { "title": "IGL continued and kinetic theory of gases", "link": "http://jamestfang.com/notes/7b/igl_kinetic_theory.pdf" }, 
      { "title": "Calorimetry, thermal processes and conduction", "link": "http://jamestfang.com/notes/7b/calorimetry_thermal_processes.pdf" }, 
      { "title": "Heat engine", "link": "http://jamestfang.com/notes/7b/heat_engine.pdf" }, 
      { "title": "Entropy and the Second Law of Thermodynamics", "link": "http://jamestfang.com/notes/7b/entropy_second_law.pdf" }, 
      { "title": "Electric charges and fields and Gauss's Law", "link": "http://jamestfang.com/notes/7b/electric_charge_fields_gauss.pdf" }, 
      { "title": "Gauss's Law continued", "link": "http://jamestfang.com/notes/7b/gauss_law.pdf" }, 
      { "title": "Conductors and electric dipoles", "link": "http://jamestfang.com/notes/7b/conductors_dipoles.pdf" }, 
      { "title": "Electric potential", "link": "http://jamestfang.com/notes/7b/electric_potential.pdf" }, 
      { "title": "Electric potential energy", "link": "http://jamestfang.com/notes/7b/electric_potential_energy.pdf" }, 
      { "title": "Capacitors", "link": "http://jamestfang.com/notes/7b/capacitors.pdf" }, 
      { "title": "Current and resistance", "link": "http://jamestfang.com/notes/7b/current_resistance.pdf" }, 
      { "title": "RC circuits", "link": "http://jamestfang.com/notes/7b/rc_circuits.pdf" }, 
      { "title": "Magnetic force", "link": "http://jamestfang.com/notes/7b/magnetic_force.pdf" }, 
      { "title": "Creating magnetic fields", "link": "http://jamestfang.com/notes/7b/creating_magnetic_fields.pdf" }, 
      { "title": "Biot savart law", "link": "http://jamestfang.com/notes/7b/biot_savart.pdf" }, 
      { "title": "Electromagnetic induction", "link": "http://jamestfang.com/notes/7b/em_induction.pdf" }, 
      { "title": "Electric field from magnetic flux, inductance, and transformers", "link": "http://jamestfang.com/notes/7b/efield_from_magnetic_flux_inductance.pdf" }, 
      { "title": "Inductance", "link": "http://jamestfang.com/notes/7b/inductance.pdf" },
      /*{ "title": "Textbook: Giancoli", "link" : "7b/txtbook.pdf" }*/
    ],
  }, // Phy 7B
  {
    title: "CS 61C: Computer architecture",
    notes: [
      { "title": "Binary and hexadecimal", "link": "http://jamestfang.com/notes/61c/binary_hex.pdf" }, 
      { "title": "Two's complement", "link": "http://jamestfang.com/notes/61c/twos_complement.pdf" }, 
      { "title": "The C Language", "link": "http://jamestfang.com/notes/61c/c.pdf" }, 
      { "title": "C memory management", "link": "http://jamestfang.com/notes/61c/memory_management.pdf" }, 
      { "title": "C GNU Debugger (GDB)", "link": "http://jamestfang.com/notes/61c/gdb.pdf" }, 
      { "title": "Assembly language: RISC-V Instruction Set Architecture (ISA)", "link": "http://jamestfang.com/notes/61c/riscv_isa.pdf" }, 
      { "title": "Machine Language", "link": "http://jamestfang.com/notes/61c/machine_language.pdf" }, 
      { "title": "From C to machine language", "link": "http://jamestfang.com/notes/61c/c_to_machine_language.pdf" }, 
      { "title": "Digital logic circuits", "link": "http://jamestfang.com/notes/61c/logic_circuits.pdf" }, 
      { "title": "The Processor: describing the hardware", "link": "http://jamestfang.com/notes/61c/cpu.pdf" }, 
      { "title": "Pipelining: parallel execution", "link": "http://jamestfang.com/notes/61c/pipelining.pdf" }, 
      { "title": "Caches", "link": "http://jamestfang.com/notes/61c/caches.pdf" }, 
      { "title": "CPU performance and floating point numbers", "link": "http://jamestfang.com/notes/61c/performance_and_floats.pdf" }, 
      { "title": "Parallel Processing: SIMD", "link": "http://jamestfang.com/notes/61c/simd.pdf" }, 
      { "title": "Parallel Processing continued", "link": "http://jamestfang.com/notes/61c/parallel_processing_cont.pdf" }, 
      { "title": "Warehouse scale computing: MapReduce, Spark", "link": "http://jamestfang.com/notes/61c/wsc.pdf" }, 
      { "title": "Operating systems", "link": "http://jamestfang.com/notes/61c/os.pdf" }, 
      { "title": "Virtual memory", "link": "http://jamestfang.com/notes/61c/vm.pdf" }, 
      { "title": "I/O: Direct Memory Access (DMA), disks, networking", "link": "http://jamestfang.com/notes/61c/io_dma_disks.pdf"
      }, 
      { "title": "Dependability and RAID", "link": "http://jamestfang.com/notes/61c/dependability_raid.pdf" }, 
      { "title": "Firebox and overview of notes", "link": "http://jamestfang.com/notes/61c/firebox_and_overview.pdf" }
    ],
  }, // CS61C
];
  